import './EasySoftwareVideoBanner.scss'

import React, { FunctionComponent } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import PuxMediaVideoSelector, {
  PuxMediaVideoSelectorType,
} from '../content/mediaVideo/PuxMediaVideoSelector'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxIconBuilder from '../PuxIconBuilder/PuxIconBuilder'

export enum VideoBannerContentAlign {
  LEFT = `left`,
  CENTER = `center`,
}

export enum VideoBannerImageStyle {
  BACKGROUND_GRADIENT = `background-gradient`,
  BACKGROUND = `background`,
  INSIDE = `inside`,
}

export interface EasySoftwareVideoBannerType {
  contentItemId?: string
  displayText: string
  videoBannerContent: PuxWysiwygHtmlBodyType
  videoBannerContentAlign: VideoBannerContentAlign
  videoBannerImageStyle: VideoBannerImageStyle
  puxButton: PuxButtonType
  videoBannerBackground: PuxMediaSelectorType
  videoBannerVideo: PuxMediaVideoSelectorType
}

const rootClass = `video-banner`
const EasySoftwareVideoBanner: FunctionComponent<EasySoftwareVideoBannerType> = (
  props
) => {
  const classes =
    `${rootClass}-${props.videoBannerContentAlign} ` +
    `${rootClass}-${props.videoBannerImageStyle} ` +
    `${rootClass}-container`

  const videoExist = props.videoBannerVideo.file?.paths.length > 0
  const showVideo =
    typeof document === `undefined`
      ? false
      : window.matchMedia(`(min-width: 991px)`).matches

  return (
    <div className={rootClass}>
      <div className={classes}>
        <div className={`${rootClass}-video`}>
          <PuxMediaSelector {...props.videoBannerBackground} />
          {videoExist && showVideo ? (
            <PuxMediaVideoSelector {...props.videoBannerVideo} />
          ) : (
            ``
          )}
        </div>
        <div className={`${rootClass}-gradient`}></div>
        <div className={`${rootClass}-foreground widget-container-padding`}>
          <div className={`${rootClass}-limiter`}>
            <div className={`${rootClass}-content`}>
              <div className={`${rootClass}-description`}>
                <PuxWysiwyg content={props.videoBannerContent} />
              </div>
            </div>
            <div className={`${rootClass}-buttons`}>
              <PuxButton {...props.puxButton} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareVideoBanner
